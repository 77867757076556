import './App.scss';
import {ChakraProvider} from "@chakra-ui/react"
import {Page} from './components/Page';
import {IntlProvider} from "react-intl";
import German from './lang/de.json';
import ReactGA from 'react-ga';

const currentUrl = window.location.toString();

let messages = German;
let locale = 'de';

switch (true) {
    case (currentUrl.search('/at') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://prima-prognose.website/at';
        locale = 'de';
        break;

    case (currentUrl.search('/ch') !== -1):
        messages = German;
        messages.outgoingUrl = 'https://prima-prognose.website/ch';
        locale = 'de';
        break;

    default:
        locale = 'de';
}

const urlParams = new URLSearchParams(window.location.search);

if (urlParams.has('campaign')) {
    const forwardParameters = ['source', 'adset_name', 'fbclid'];

    let binomUrl = 'https://binom.7sg.site/_click-api/index.php?campaign=' + urlParams.get('campaign');

    forwardParameters.forEach(function (forwardedParameter) {
        if (urlParams.has(forwardedParameter)) {
            binomUrl += '&' + forwardedParameter + '=' + urlParams.get(forwardedParameter);
        }
    });

    let request = new XMLHttpRequest();
    request.open('GET', binomUrl, true);

    request.onload = function () {
        if (this.status >= 200 && this.status < 400) {
            let responseJson = JSON.parse(this.response);
            messages.outgoingUrl = 'https://binom.7sg.site/click.php?lp=1&uclick=' + responseJson.clickId
        }
    };

    request.send();
}

document.title = 'PRIMA - ' + messages.title;

function App() {
    ReactGA.initialize('UA-183263461-20');
    ReactGA.pageview(window.location.pathname + window.location.search);

    return (
        <div className="App">
            <IntlProvider locale={locale} messages={messages} defaultLocale="de">
                <ChakraProvider>
                    <Page/>
                </ChakraProvider>
            </IntlProvider>
        </div>
    );
}

export default App;
