import React from 'react';
import {Box, Container} from '@chakra-ui/react';
import {Header} from './Header';

export const Layout = ({children}) => {
    return (
        <Box>
            <Box textAlign={"center"} pt={3} textColor={"#718096"} textTransform={"uppercase"} fontSize={"0.8rem"}>
                Advertorial
            </Box>

            <Header/>

            <Container className="main-contianer" maxW="container.lg">
                {children}
            </Container>
        </Box>
    );
};
