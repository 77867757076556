import React, {useState} from 'react'
import {Box, Container, GridItem, Icon, Grid, ListItem, List, Stack, Flex, Spacer, SimpleGrid} from '@chakra-ui/react';
import {Layout} from './layout/Layout'
import {ExternalLinkIcon} from "@chakra-ui/icons";
import {FormattedMessage, useIntl} from "react-intl";

export const Page = () => {
    let [currentStep, setCurrentStep] = useState(1);

    const intl = useIntl()
    let outgoingLink = intl.formatMessage({id: "outgoingUrl"});
    let isV2 = false;

    if (window.location.toString().search('/de_v2') !== -1) {
        outgoingLink = intl.formatMessage({id: "outgoingUrlV2"});
        isV2 = true;
    }

    if (window.location.search !== '' && outgoingLink.search('binom') === -1) {
        outgoingLink += window.location.search;
    }

    const nextStep = () => {
        setCurrentStep(2);
    };

    const VolumeIcon = (props) => (
        <Icon viewBox="0 0 16 16" {...props}>
            <path fill="currentColor"
                  d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/>
            <path fill="currentColor"
                  d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/>
            <path fill="currentColor"
                  d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/>
        </Icon>
    )

    return (
        <Layout>
            {currentStep === 1 &&
                <Box textAlign={"center"}>
                    <div className={"video"}>
                        <iframe src="https://fast.wistia.net/embed/iframe/wn2tpcawv7?videoFoam=true"
                                allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                                title={"video1"}
                                allowFullScreen={true}></iframe>
                    </div>

                    <Box bgColor={"#2d78f7"} p={2} color={"white"} display="flex" alignItems={"center"}
                         justifyContent={"center"} fontSize={"xs"}>
                        <Icon w={5} h={5} mr={1} as={VolumeIcon}/>
                        Bitte schalte den Ton an, wenn du das Video abspielst.
                    </Box>

                    <Box mt={5}>
                        <FormattedMessage id="button.first.text"/>
                    </Box>

                    <a className={"pulse chakra-button primary-btn"} mt={5} onClick={nextStep}>
                        <FormattedMessage id="button.next"/>
                    </a>
                </Box>
            }

            {currentStep === 2 &&
                <Box textAlign={"center"}>
                    <div className={"video"}>
                        <iframe
                            src="https://fast.wistia.net/embed/iframe/24139osgk7?videoFoam=true"
                            allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
                            title={"video2"}
                            allowFullScreen={true}></iframe>
                    </div>

                    <Box bgColor={"#2d78f7"} p={2} color={"white"} display="flex" alignItems={"center"}
                         justifyContent={"center"} fontSize={"xs"}>
                        <Icon w={5} h={5} mr={1} as={VolumeIcon}/>
                        Bitte schalte den Ton an, wenn du das Video abspielst.
                    </Box>

                    <Box mt={5}>
                        <FormattedMessage id="button.second.text"/>
                    </Box>

                    <a className={"pulse chakra-button primary-btn"} href={outgoingLink}>
                        {isV2 === true &&
                            <FormattedMessage id="button.secondV2"/>
                        }
                        {isV2 === false &&
                            <FormattedMessage id="button.second"/>
                        }
                    </a>
                </Box>
            }

            <Box id={"footer"} mt={10} borderTop="2px" fontSize="xs" borderColor="gray.200" textAlign={"center"}>
                <Container className="main-contianer" maxW="container.lg">
                    <SimpleGrid columns={[1, null, 2]}>
                        <GridItem pt={5} pb={5} textAlign={{lg: 'left', md: 'left'}}>
                            Copyright © 2022 PRIMA - All Rights Reserved.
                        </GridItem>
                        <GridItem pt={5} pb={5} textAlign={{lg: 'right', md: 'right'}}>
                            <a style={{display: "inline-block", marginRight: "7px"}} target={"_blank"}
                               rel={"nofollow"}
                               href="impressum.html">
                                <Icon as={ExternalLinkIcon}/> Impressum & Kontakt
                            </a>

                            <a style={{display: "inline-block", marginRight: "7px"}} target={"_blank"}
                               rel={"nofollow"}
                               href="datenschutz.html">
                                <Icon as={ExternalLinkIcon}/> Datenschutzrichtlinie
                            </a>
                        </GridItem>
                    </SimpleGrid>

                    <Box textAlign={'left'} textColor={"#718096"}>
                        <Box mb={3}>Die Informationen auf dieser Webseite wurden ausgiebig recherchiert und geprüft. Jedoch
                            müssen wir darauf hinweisen, dass die Verantwortung bei dem Konsument dieser Webseite liegt.
                            Wir bitten Sie die Informationen selbstständig gründlich zu überprüfen.
                        </Box>

                        <Box mb={3}>
                            Die vorgestellten Produkte, die auf dieser Website erscheinen, gehören Unternehmen, von
                            denen wir möglicherweise eine Vergütung erhalten oder an denen wir beteiligt sind. Die
                            Vergütung kann sich darauf auswirken, wie und wo die Produkte auf der Website erscheinen,
                            einschließlich der Reihenfolge, in der sie erscheinen. Das Ranking und die Reihenfolge der
                            Produkte auf der Website können sich ändern, und die Website enthält nicht alle verfügbaren
                            Produkte in ihren jeweiligen Kategorien. Zusätzliche Elemente, die sich auf unsere Rankings
                            auswirken, können das Gerät des Nutzers, das Betriebssystem, der Standort, der Wochentag,
                            die Tageszeit und Cookie-Daten oder alle selbst erhobenen Daten sein, die wir auf dieser
                            Website generieren können. Diese Aussagen wurden nicht von der FDA, Lebensmittel und
                            Medikamenten Verwaltung, ausgewertet. Diese Produkte sind nicht dafür einsetzbar, um
                            Diagnosen, Behandlungen, Heilungen oder Vorbeugung irgendwelcher Art von Krankheiten
                            auszuführen. Die Informationen auf dieser Webseite oder dieser Firma, stellen keinerlei Art
                            von Ersatz einer persönlichen Behandlung deines Arztes dar und sollten nicht als
                            medizinischer Rat angesehen werden. Die Referenzen auf dieser Webseite sind Einzelfälle und
                            garantieren nicht, dass dies bei dir genauso ausfallen wird. Der gesamte Inhalt, inklusive
                            Text, Grafiken, Fotos und Informationen über oder durch diese Webseite sind nur für Zwecke
                            zur allgemeinen Information gedacht.Geschützte Marken, die auf dieser Webseite genutzt
                            werden, stehen im Eigentum derer Besitzer und keine implizite oder ausgesprochene
                            Bestätigung seitens unserer Webseite ist vorgedacht. Bewertungen können zwischen Smarter
                            Choices und unserer Top-Auswahl beeinflusst werden.
                        </Box>

                        <Box mb={3}>
                            Diese Website und die Produkte & Dienstleistungen, auf die auf der Website verwiesen wird,
                            sind Werbung. Diese Website ist Werbung und keine Nachrichtenpublikation. Alle Personen, die
                            auf den Fotos dieser Website erscheinen, sind Modelle. Der Eigentümer dieser Website und der
                            Produkte und Dienstleistungen, auf die auf dieser Website Bezug genommen wird, bietet nur
                            einen Service an, bei dem Verbraucher etwas vergleichen und beziehen können.
                        </Box>

                        <Box mb={3}>
                            Diese Produkte sind nicht dazu bestimmt, Krankheiten zu diagnostizieren, zu behandeln, zu heilen oder zu verhindern.
                        </Box>
                    </Box>
                </Container>
            </Box>
        </Layout>
    )
}
