import React from 'react';
import { Box, Container, Heading, Text } from '@chakra-ui/react';

import { FormattedMessage } from "react-intl";

export const Header = () => {
    return (
        <Box className="header-container" textAlign={"center"} w="100%" pb={5} pt={3}>
            <Container maxW="container.lg">
                <Heading mb={5}><FormattedMessage id="header.headline"/></Heading>
                <Text fontSize={20}><FormattedMessage id="header.subline" /></Text>
            </Container>
        </Box>
    )
}
